<template>
  <el-row type="flex" justify="center">
    <b class="ques-field-top">
      Questions :: {{ lastInteactiveQuestionIndex + 1 }} /
      {{ fieldsData.length }}
    </b>
    <el-col :span="20" v-if="interactiveQuestionsModeType == 'vertical'">
      <div v-if="allFieldsCompleted" class="mt-3" style="margin-left: 25%">
        All fields completed
      </div>
      <el-form v-else>
        <div
          class="form-builder"
          ref="formBuilder"
          id="formBuilder"
          :style="getStylediv"
        >
          <pulse-loader
            v-if="!completedFieldsData.length && interactiveQuestionsLoading"
          ></pulse-loader>
          <div
            v-if="completedFieldsData && completedFieldsData.length"
            class="form-fields-holder"
          >
            <div
              v-for="(field, index) of completedFieldsData"
              :key="index"
              :style="getStyle(field)"
              :id="`currentQuestion` + index"
            >
              <DateExecute
                :data="field"
                v-if="
                  field.input_type === 'DATE'
                  // ||
                  // field.input_type === 'TIME'
                  //  ||
                  // field.input_type === 'DATE_TIME'
                "
                :form="form"
                :hasLabel="hasLabel"
              ></DateExecute>
              <DateTimeExecute
                :data="field"
                v-if="field.input_type === 'DATE_TIME'"
                :form="form"
                :hasLabel="hasLabel"
              ></DateTimeExecute>
              <DateTimeRangeExecute
                :data="field"
                v-if="field.input_type === 'DATE_TIME_RANGE'"
                :form="form"
                :hasLabel="hasLabel"
              ></DateTimeRangeExecute>
              <DateRangeExecute
                :data="field"
                v-if="field.input_type === 'DATE_RANGE'"
                :form="form"
                :hasLabel="hasLabel"
              ></DateRangeExecute>
              <TimeExecute
                :data="field"
                v-if="field.input_type === 'TIME'"
                :form="form"
                :hasLabel="hasLabel"
              ></TimeExecute>
              <TimeRangeExecute
                :data="field"
                v-if="field.input_type === 'TIME_RANGE'"
                :form="form"
                :hasLabel="hasLabel"
              ></TimeRangeExecute>
              <FileExecuteView
                :data="field"
                v-if="field.input_type === 'FILE'"
                :hasLabel="hasLabel"
              ></FileExecuteView>
              <AutoIncrementExecute
                 :data="field"
                 v-if="field.input_type === 'AUTO_INCREMENT_NUMBER'"
                 :form="form"
                 :hasLabel="hasLabel"
                  ></AutoIncrementExecute>

              <MultiLineTextExecute
                :data="field"
                v-if="
                  field.input_type === 'MULTI_LINE_TEXT' ||
                  field.input_type === 'RICH_TEXT'
                "
                :form="form"
                :hasLabel="hasLabel"
              ></MultiLineTextExecute>
              <SingleLineTextExecute
                :data="field"
                v-if="
                  field.input_type === 'SINGLE_LINE_TEXT' ||
                  field.input_type === 'EMAIL' ||
                  field.input_type === 'PASSWORD' ||
                  field.input_type === 'MASKED' ||
                  field.input_type === 'HYPER_LINK'
                "
                :form="form"
                :hasLabel="hasLabel"
              ></SingleLineTextExecute>
              <MultiSelectExecute
                :data="field"
                v-if="field.input_type === 'MULTI_SELECT'"
                :form="form"
                :hasLabel="hasLabel"
              ></MultiSelectExecute>
              <SelectExecute
                :data="field"
                v-if="field.input_type === 'SELECT'"
                :form="form"
                :hasLabel="hasLabel"
              ></SelectExecute>
              <YesOrNoExecute
                :data="field"
                v-if="field.input_type === 'YES_OR_NO'"
                :form="form"
                :hasLabel="hasLabel"
              ></YesOrNoExecute>
              <NumberExecute
                :data="field"
                v-if="
                  field.input_type === 'NUMBER' || field.input_type === 'PHONE'
                "
                :form="form"
                :hasLabel="hasLabel"
              ></NumberExecute>
              <CurrencyExecute
                :data="field"
                v-if="field.input_type === 'CURRENCY'"
                :form="form"
                :hasLabel="hasLabel"
              ></CurrencyExecute>
              <PaymentExecute
                :data="field"
                v-if="field.input_type === 'PAY_BUTTON'"
                :form="form"
                :hasLabel="hasLabel"
              ></PaymentExecute>


              <PhoneCountryCodeExecute
                :data="field"
                v-if="field.input_type === 'PHONE_COUNTRY_CODE'"
                :form="form"
                :hasLabel="hasLabel"
              ></PhoneCountryCodeExecute>
              <HeadingExecute
                :data="field"
                v-if="field.input_type === 'HEADING'"
                :form="form"
              ></HeadingExecute>
              <CheckBoxExecute
                :data="field"
                v-if="field.input_type === 'CHECKBOX'"
                :form="form"
                :hasLabel="hasLabel"
              ></CheckBoxExecute>
              <RadioExecute
                :data="field"
                v-if="field.input_type === 'RADIO'"
                :form="form"
                :hasLabel="hasLabel"
              ></RadioExecute>
              <ESignatureExecute
                :data="field"
                v-if="field.input_type === 'SIGNATURE'"
                :form="form"
              ></ESignatureExecute>

              <GlobalVariableExecute
                :data="field"
                v-if="field.input_type === 'GLOBAL_VARIABLE'"
                :form="form"
                :fieldsData="fieldsData"
                :hasLabel="hasLabel"
              ></GlobalVariableExecute>

              <EntityVariableExecute
                :data="field"
                v-if="field.input_type === 'ENTITY_VARIABLE'"
                :form="form"
                :hasLabel="hasLabel"
                :fieldsData="fieldsData"
              ></EntityVariableExecute>

              <RadioGroupExecute
                :data="field"
                v-if="field.input_type === 'RADIO_BUTTON_GROUP'"
                :form="form"
                :hasLabel="hasLabel"
                :fieldsData="getFieldsData"
              ></RadioGroupExecute>

              <CheckBoxGroupExecute
                :data="field"
                v-if="field.input_type === 'CHECKBOX_GROUP'"
                :form="form"
                :hasLabel="hasLabel"
                :fieldsData="getFieldsData"
              ></CheckBoxGroupExecute>

              <EntityExecute
                :data="field"
                v-if="field.input_type === 'ENTITY'"
                :form="form"
                :hasLabel="hasLabel"
                :fieldsData="fieldsData"
              ></EntityExecute>

              <ParagraphExecute
                :data="field"
                v-if="field.input_type === 'PARAGRAPH'"
                :form="form"
              ></ParagraphExecute>
              <SingleLineContentExecute
                :data="field"
                v-if="field.input_type === 'SINGLE_LINE_CONTENT'"
                :form="form"
              ></SingleLineContentExecute>
              <AuthorizedSignatureExecute
                :data="field"
                v-if="field.input_type === 'AUTHORIZED_SIGNATURE'"
                :form="form"
              ></AuthorizedSignatureExecute>
              <ImageExecute
                :data="field"
                v-if="field.input_type === 'IMAGE' ||
                field.input_type === 'GIF'"
                :form="form"
              ></ImageExecute>
              <VideoExecute
                :data="field"
                v-if="field.input_type === 'VIDEO'"
                :form="form"
              ></VideoExecute>
              <FormulaExecute
                :data="field"
                v-if="field.input_type === 'FORMULA'"
                :form="form"
              ></FormulaExecute>
              <ActionButtonExecute
                :data="field"
                v-if="field.input_type === 'ACTION_BUTTON'"
                :form="form"
                :fieldsData="fieldsData"
              ></ActionButtonExecute>
              <HtmlExecute
                :data="field"
                v-if="field.input_type === 'HTML_CONTENT'"
                :form="form"
              ></HtmlExecute>
              <HorizontalLineExecute
                :data="field"
                v-if="field.input_type === 'HORIZONTAL_LINE'"
                :form="form"
              >
              </HorizontalLineExecute>

              <DIVExecute
                :data="field"
                v-if="field.input_type === 'DIVISION'"
                :form="form"
              >
              </DIVExecute>

              <QuestionExecuteInteractive
                :data="field"
                v-if="field.input_type === 'QUESTION'"
                :form="form"
                :is-view="false"
                @goto-next="moveToNextQuestion"
                @goto-prev="moveToPrevQuestion"
                :nextIndex="index"
                :currentQuestionIndexs="currentQuestionIndexs"
                :lastInteactiveQuestionIndex="lastInteactiveQuestionIndex"
                :interactiveQuestionsModeType="interactiveQuestionsModeType"
                :fieldsData="fieldsData"
              ></QuestionExecuteInteractive>

              <AggregateFunctionExecute
                :data="field"
                v-if="field.input_type === 'AGGREGATE_FUNCTION'"
                :form="form"
                :hasLabel="hasLabel"
              ></AggregateFunctionExecute>
              <WeekDaysExecute
                :data="field"
                v-if="field.input_type == 'WEEKDAYS'"
                :form="form"
                :hasLabel="hasLabel"
              >
              </WeekDaysExecute>
              <StarRatingExecute
                :data="field"
                v-if="field.input_type == 'STAR_RATING'"
                :form="form"
                :hasLabel="hasLabel"
              >
              </StarRatingExecute>
              <ConcatenateExecute
                :data="field"
                v-if="field.input_type === 'CONCATENATE'"
                :form="form"
                :hasLabel="hasLabel"
              ></ConcatenateExecute>
              <pulse-loader
                v-if="
                  index == lastInteactiveQuestionIndex &&
                  completedFieldsData.length &&
                  interactiveQuestionsLoading &&
                  fieldsData.length > completedFieldsData.length
                "
              ></pulse-loader>
              <template
                v-if="
                  interactiveQuestionsModeType == 'horizontal' &&
                  field.input_type !== 'QUESTION' &&
                  field.question_type !== 'TEXT'
                "
              >
                <el-button
                  v-if="
                    index == lastInteactiveQuestionIndex &&
                    currentQuestionIndexs[0] != 0
                  "
                  @click="moveToPrevQuestion"
                  style="position: relative; margin-top: 10px"
                  size="small"
                  :loading="interactiveQuestionsLoading"
                  >Prev</el-button
                >
                <!-- Don't remove it -->
                <!-- fieldsData.length != completedFieldsData.length -->
                <el-button
                  v-if="index == lastInteactiveQuestionIndex"
                  @click="moveToNextQuestion"
                  :disabled="isCurrentFieldCompleted"
                  style="position: relative"
                  size="small"
                  :loading="interactiveQuestionsLoading"
                  >Next</el-button
                >
              </template>
              <template
                v-else-if="
                  field.input_type !== 'QUESTION' &&
                  field.question_type !== 'TEXT'
                "
              >
                <el-button
                  v-if="index == lastInteactiveQuestionIndex"
                  @click="moveToNextQuestion"
                  style="position: relative; margin-top: 10px"
                  size="small"
                  :disabled="isCurrentFieldCompleted"
                  :loading="interactiveQuestionsLoading"
                  >Next</el-button
                >
              </template>
            </div>
          </div>
        </div>
      </el-form>
    </el-col>
    <el-col :span="20" v-else>
      <el-form>
        <div
          class="form-builder"
          ref="formBuilder"
          :style="getStylediv"
          id="formBuilder"
        >
          <pulse-loader
            v-if="!completedFieldsData.length && interactiveQuestionsLoading"
          ></pulse-loader>
          <div v-if="allFieldsCompleted" class="complete-msg">
            All fields completed
          </div>
          <div v-else class="form-fields-holder">
            <div
              v-for="(field, index) of completedFieldsData"
              :key="index"
              :style="getStyle(field)"
              :id="`currentQuestion` + index"
            >
              <div v-if="currentQuestionIndexs.indexOf(index) > -1">
                <DateTimeRangeExecute
                  :data="field"
                  v-if="field.input_type === 'DATE_TIME_RANGE'"
                  :form="form"
                  :hasLabel="hasLabel"
                ></DateTimeRangeExecute>
                <DateRangeExecute
                  :data="field"
                  v-if="field.input_type === 'DATE_RANGE'"
                  :form="form"
                  :hasLabel="hasLabel"
                ></DateRangeExecute>
                <DateExecute
                  :data="field"
                  v-if="
                    field.input_type === 'DATE'
                    // ||
                    //  field.input_type === 'TIME'
                    // ||
                    // field.input_type === 'DATE_TIME'
                  "
                  :form="form"
                  :hasLabel="hasLabel"
                ></DateExecute>
                <DateTimeExecute
                  :data="field"
                  v-if="field.input_type === 'DATE_TIME'"
                  :form="form"
                  :hasLabel="hasLabel"
                ></DateTimeExecute>
                <TimeExecute
                  :data="field"
                  v-if="field.input_type === 'TIME'"
                  :form="form"
                  :hasLabel="hasLabel"
                ></TimeExecute>
                <TimeRangeExecute
                  :data="field"
                  v-if="field.input_type === 'TIME_RANGE'"
                  :form="form"
                  :hasLabel="hasLabel"
                ></TimeRangeExecute>
                <FileExecuteView
                  :data="field"
                  v-if="field.input_type === 'FILE'"
                  :hasLabel="hasLabel"
                ></FileExecuteView>

                <MultiLineTextExecute
                  :data="field"
                  v-if="
                    field.input_type === 'MULTI_LINE_TEXT' ||
                    field.input_type === 'RICH_TEXT'
                  "
                  :form="form"
                  :hasLabel="hasLabel"
                ></MultiLineTextExecute>
                <SingleLineTextExecute
                  :data="field"
                  v-if="
                    field.input_type === 'SINGLE_LINE_TEXT' ||
                    field.input_type === 'EMAIL' ||
                    field.input_type === 'PASSWORD' ||
                    field.input_type === 'MASKED' ||
                    field.input_type === 'HYPER_LINK'
                  "
                  :form="form"
                  :hasLabel="hasLabel"
                ></SingleLineTextExecute>
                <MultiSelectExecute
                  :data="field"
                  v-if="field.input_type === 'MULTI_SELECT'"
                  :form="form"
                  :hasLabel="hasLabel"
                ></MultiSelectExecute>
                <SelectExecute
                  :data="field"
                  v-if="field.input_type === 'SELECT'"
                  :form="form"
                  :hasLabel="hasLabel"
                ></SelectExecute>
                <YesOrNoExecute
                  :data="field"
                  v-if="field.input_type === 'YES_OR_NO'"
                  :form="form"
                  :hasLabel="hasLabel"
                ></YesOrNoExecute>
                <NumberExecute
                  :data="field"
                  v-if="
                    field.input_type === 'NUMBER' ||
                    field.input_type === 'PHONE'
                  "
                  :form="form"
                  :hasLabel="hasLabel"
                ></NumberExecute>
                <CurrencyExecute
                  :data="field"
                  v-if="field.input_type === 'CURRENCY'"
                  :form="form"
                  :hasLabel="hasLabel"
                ></CurrencyExecute>
                <PaymentExecute
                :data="field"
                v-if="field.input_type === 'PAY_BUTTON'"
                :form="form"
                :hasLabel="hasLabel"
              ></PaymentExecute>
                
                <PhoneCountryCodeExecute
                  :data="field"
                  v-if="field.input_type === 'PHONE_COUNTRY_CODE'"
                  :form="form"
                  :hasLabel="hasLabel"
                ></PhoneCountryCodeExecute>
                <HeadingExecute
                  :data="field"
                  v-if="field.input_type === 'HEADING'"
                  :form="form"
                ></HeadingExecute>
                <CheckBoxExecute
                  :data="field"
                  v-if="field.input_type === 'CHECKBOX'"
                  :form="form"
                  :hasLabel="hasLabel"
                ></CheckBoxExecute>
                <RadioExecute
                  :data="field"
                  v-if="field.input_type === 'RADIO'"
                  :form="form"
                  :hasLabel="hasLabel"
                ></RadioExecute>
                <ESignatureExecute
                  :data="field"
                  v-if="field.input_type === 'SIGNATURE'"
                  :form="form"
                ></ESignatureExecute>

                <GlobalVariableExecute
                  :data="field"
                  v-if="field.input_type === 'GLOBAL_VARIABLE'"
                  :form="form"
                  :fieldsData="fieldsData"
                  :hasLabel="hasLabel"
                ></GlobalVariableExecute>

                <EntityVariableExecute
                  :data="field"
                  v-if="field.input_type === 'ENTITY_VARIABLE'"
                  :form="form"
                  :hasLabel="hasLabel"
                  :fieldsData="fieldsData"
                ></EntityVariableExecute>

                <RadioGroupExecute
                  :data="field"
                  v-if="field.input_type === 'RADIO_BUTTON_GROUP'"
                  :form="form"
                  :hasLabel="hasLabel"
                  :fieldsData="getFieldsData"
                ></RadioGroupExecute>

                <CheckBoxGroupExecute
                  :data="field"
                  v-if="field.input_type === 'CHECKBOX_GROUP'"
                  :form="form"
                  :hasLabel="hasLabel"
                  :fieldsData="getFieldsData"
                ></CheckBoxGroupExecute>

                <EntityExecute
                  :data="field"
                  v-if="field.input_type === 'ENTITY'"
                  :form="form"
                  :hasLabel="hasLabel"
                  :fieldsData="fieldsData"
                ></EntityExecute>

                <ParagraphExecute
                  :data="field"
                  v-if="field.input_type === 'PARAGRAPH'"
                  :form="form"
                ></ParagraphExecute>
                <SingleLineContentExecute
                  :data="field"
                  v-if="field.input_type === 'SINGLE_LINE_CONTENT'"
                  :form="form"
                ></SingleLineContentExecute>
                <AuthorizedSignatureExecute
                  :data="field"
                  v-if="field.input_type === 'AUTHORIZED_SIGNATURE'"
                  :form="form"
                ></AuthorizedSignatureExecute>
                <ImageExecute
                  :data="field"
                  v-if="field.input_type === 'IMAGE' ||
                  field.input_type === 'GIF'"
                  :form="form"
                ></ImageExecute>
                <VideoExecute
                  :data="field"
                  v-if="field.input_type === 'VIDEO'"
                  :form="form"
                ></VideoExecute>
                <FormulaExecute
                  :data="field"
                  v-if="field.input_type === 'FORMULA'"
                  :form="form"
                ></FormulaExecute>
                <ActionButtonExecute
                  :data="field"
                  v-if="field.input_type === 'ACTION_BUTTON'"
                  :form="form"
                  :fieldsData="fieldsData"
                ></ActionButtonExecute>
                <HtmlExecute
                  :data="field"
                  v-if="field.input_type === 'HTML_CONTENT'"
                  :form="form"
                ></HtmlExecute>
                <HorizontalLineExecute
                  :data="field"
                  v-if="field.input_type === 'HORIZONTAL_LINE'"
                  :form="form"
                >
                </HorizontalLineExecute>

                <DIVExecute
                  :data="field"
                  v-if="field.input_type === 'DIVISION'"
                  :form="form"
                >
                </DIVExecute>

                <QuestionExecuteInteractive
                  :data="field"
                  v-if="field.input_type === 'QUESTION'"
                  :form="form"
                  :is-view="false"
                  @goto-next="moveToNextQuestion"
                  @goto-prev="moveToPrevQuestion"
                  :nextIndex="index"
                  :currentQuestionIndexs="currentQuestionIndexs"
                  :lastInteactiveQuestionIndex="lastInteactiveQuestionIndex"
                  :interactiveQuestionsModeType="interactiveQuestionsModeType"
                  :fieldsData="fieldsData"
                >
                </QuestionExecuteInteractive>

                <AggregateFunctionExecute
                  :data="field"
                  v-if="field.input_type === 'AGGREGATE_FUNCTION'"
                  :form="form"
                  :hasLabel="hasLabel"
                ></AggregateFunctionExecute>
                <WeekDaysExecute
                  :data="field"
                  v-if="field.input_type == 'WEEKDAYS'"
                  :form="form"
                  :hasLabel="hasLabel"
                >
                </WeekDaysExecute>
                <StarRatingExecute
                  :data="field"
                  v-if="field.input_type == 'STAR_RATING'"
                  :form="form"
                  :hasLabel="hasLabel"
                >
                </StarRatingExecute>
                <AutoIncrementExecute
                 :data="field"
                 v-if="field.input_type === 'AUTO_INCREMENT_NUMBER'"
                 :form="form"
                 :hasLabel="hasLabel"
                  ></AutoIncrementExecute>
                  <ConcatenateExecute
                  :data="field"
                  v-if="field.input_type === 'CONCATENATE'"
                  :form="form"
                  :hasLabel="hasLabel"
                ></ConcatenateExecute>
              </div>

              <pulse-loader
                v-if="
                  index == lastInteactiveQuestionIndex &&
                  completedFieldsData.length &&
                  interactiveQuestionsLoading &&
                  fieldsData.length > completedFieldsData.length
                "
              ></pulse-loader>

              <template
                v-if="
                  interactiveQuestionsModeType == 'horizontal' &&
                  field.input_type !== 'QUESTION' &&
                  field.question_type !== 'TEXT'
                "
              >
                <el-button
                  v-if="
                    index == lastInteactiveQuestionIndex &&
                    currentQuestionIndexs[0] != 0
                  "
                  @click="moveToPrevQuestion"
                  style="position: relative; margin-top: 10px"
                  size="small"
                  :loading="interactiveQuestionsLoading"
                  >Prev</el-button
                >
                <!-- Don't remove it -->
                <!-- fieldsData.length != completedFieldsData.length -->
                <el-button
                  v-if="index == lastInteactiveQuestionIndex"
                  @click="moveToNextQuestion"
                  :disabled="isCurrentFieldCompleted"
                  style="position: relative; margin-top:10px"
                  size="small"
                  :loading="interactiveQuestionsLoading"
                  >Next</el-button
                >
              </template>
              <template
                v-else-if="
                  field.input_type !== 'QUESTION' &&
                  field.question_type !== 'TEXT'
                "
              >
                <el-button
                  v-if="index == lastInteactiveQuestionIndex"
                  @click="moveToNextQuestion"
                  style="position: relative; margin-top: 10px"
                  size="small"
                  :disabled="isCurrentFieldCompleted"
                  :loading="interactiveQuestionsLoading"
                  >Next</el-button
                >
              </template>
            </div>
          </div>
        </div>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { mapGetters } from "vuex";
// import VueScrollTo from "vue-scrollto";
var fieldsList = require("./fields.json");

export default {
  components: {
    PulseLoader,
    DateExecute: () => import("./formComponentsExecute/DateExecute.vue"),
    DateTimeExecute: () =>
      import("./formComponentsExecute/DateTimeExecute.vue"),
    FileExecuteView: () =>
      import("./formComponentsExecute/FileExecuteView.vue"),
    TimeExecute: () => import("./formComponentsExecute/TimeExecute"),
    TimeRangeExecute: () =>
      import("./formComponentsExecute/TimeRangeExecute.vue"),
    MultiLineTextExecute: () =>
      import("./formComponentsExecute/MultiLineTextExecute.vue"),
    SingleLineTextExecute: () =>
      import("./formComponentsExecute/SingleLineTextExecute.vue"),
      AutoIncrementExecute: () =>
      import("./formComponentsExecute/AutoIncrementExecute.vue"),
    MultiSelectExecute: () =>
      import("./formComponentsExecute/MultiSelectExecute.vue"),
    NumberExecute: () => import("./formComponentsExecute/NumberExecute.vue"),
    CurrencyExecute: () => import("./formComponentsExecute/CurrencyExecute"),
    PaymentExecute: () => import("./formComponentsExecute/PaymentExecute"),
    
    PhoneCountryCodeExecute: () =>
      import("./formComponentsExecute/PhoneCountryCodeExecute.vue"),
    SelectExecute: () => import("./formComponentsExecute/SelectExecute.vue"),
    YesOrNoExecute: () => import("./formComponentsExecute/YesOrNoExecute.vue"),
    HeadingExecute: () => import("./formComponentsExecute/HeadingExecute.vue"),
    CheckBoxExecute: () =>
      import("./formComponentsExecute/CheckBoxExecute.vue"),
    RadioExecute: () => import("./formComponentsExecute/RadioExecute.vue"),
    ESignatureExecute: () =>
      import("./formComponentsExecute/ESignatureExecute.vue"),
    ParagraphExecute: () =>
      import("./formComponentsExecute/ParagraphExecute.vue"),
    GlobalVariableExecute: () =>
      import("./formComponentsExecute/GlobalVariableExecute.vue"),
    EntityVariableExecute: () =>
      import("./formComponentsExecute/EntityVariableExecute.vue"),
    EntityExecute: () => import("./formComponentsExecute/EntityExecute.vue"),
    DateTimeRangeExecute: () =>
      import("./formComponentsExecute/DateTimeRangeExecute.vue"),
    DateRangeExecute: () =>
      import("./formComponentsExecute/DateRangeExecute.vue"),
    CheckBoxGroupExecute: () =>
      import("./formComponentsExecute/CheckBoxGroupExecute"),
    RadioGroupExecute: () =>
      import("./formComponentsExecute/RadioButtonGroupExecute"),
    SingleLineContentExecute: () =>
      import("./formComponentsExecute/SingleLineContentExecute.vue"),
    AuthorizedSignatureExecute: () =>
      import("./formComponentsExecute/AuthorizedSignatureExecute.vue"),
    ImageExecute: () => import("./formComponentsExecute/ImageExecute.vue"),
    FormulaExecute: () => import("./formComponentsExecute/FormulaExecute.vue"),
    ActionButtonExecute: () =>
      import("./formComponentsExecute/ActionButtonExecute.vue"),
    HtmlExecute: () => import("./formComponentsExecute/HtmlExecute.vue"),
    AggregateFunctionExecute: () =>
      import("./formComponentsExecute/AggregateFunctionExecute.vue"),
    VideoExecute: () => import("./formComponentsExecute/VideoExecute.vue"),
    HorizontalLineExecute: () =>
      import("./formComponentsExecute/HorizontalLineExecute.vue"),
    DIVExecute: () => import("./formComponentsExecute/DIVExecute.vue"),
    WeekDaysExecute: () =>
      import("./formComponentsExecute/WeekDaysExecute.vue"),
    QuestionExecuteInteractive: () =>
      import("./formComponentsExecute/QuestionExecuteInteractive.vue"),
   StarRatingExecute: () =>
      import("./formComponentsExecute/StarRatingExecute.vue"),
      ConcatenateExecute: () =>
      import("./formComponentsExecute/ConcatenateExecute.vue"),
  },
  data() {
    return {
      form: {},
      fieldsData: [],
      currentFormBuilderWidth: null,
      name: "",
      description: "",
      type: "",
      slug: "",
      storedFormBuilderWidth: null,
      isFlow: false,
      componentName: "",
      formHeight: 600,
      loading: false,
      fieldActions: [],
      templateIconPath: "block-portfolio.svg",
      templateStyles: {},
      hasLabel: true,
      interactiveQuestionsMode: false,
      interactiveQuestionsModeType: "vertical",

      interactiveQuestionsLoading: false,
      completedFieldsData: [],
      lastInteactiveQuestionIndex: -1,
      currentQuestionIndexs: [],
      allFieldsCompleted: false,
    };
  },

  computed: {
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    ...mapGetters("companyTemplates", [
      "getTemplateAddStatus",
      "getTemplateUpdateStatus",
      "getSingleCompanyTemplate",
      "getSingleDefaultTemplate",
      "getTemplateErrors",
    ]),
    ...mapGetters("standardFields", [
      "getFList",
      "getField",
      "getGeneralErrorMessage",
    ]),

    getTemplateSlug() {
      return this.name.toLowerCase().split(" ").join("-");
    },
    getStylediv() {
      return `height:${this.formHeight}px;` + this.getElementStyle;
    },
    isCurrentFieldCompleted() {
      const currentField =
        this.completedFieldsData[this.lastInteactiveQuestionIndex];
      if (
        currentField &&
        currentField.key &&
        currentField.validations &&
        currentField.validations.required
      ) {
        return !(
          this.form[currentField.key] &&
          this.form[currentField.key] != "" &&
          this.form[currentField.key] != null &&
          this.form[currentField.key] != undefined
        );
      }
      return false;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.templateStyles) {
        let type = this.templateStyles.border_type
          ? this.templateStyles.border_type
          : "solid";
        let size = this.templateStyles.border_size
          ? this.templateStyles.border_size + "px"
          : "0px";
        let color = this.templateStyles.border_color
          ? this.templateStyles.border_color
          : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.templateStyles.background
          ? `background: ${this.templateStyles.background}`
          : "";
      }
      if (this.interactiveQuestionsMode) {
        borderStyle += borderStyle + "overflow: scroll;";
      }
      return borderStyle;
    },
    fieldActionsObject() {
      const obj = {};
      this.fieldActions.forEach((action) => {
        obj[action.inputType] = action;
      });

      return obj;
    },
  },

  async mounted() {
    await this.getAllFieldList();
    this.currentFormBuilderWidth = this.$refs.formBuilder.clientWidth;

    if (this.$route.params.template_id) {
      const isDefaultTemplate = false;
      this.selectedTemplateId = this.$route.params.template_id;
      await this.setTemplateInfo(this.selectedTemplateId, isDefaultTemplate);
    }

    this.$store.commit(
      "companyTemplates/setTemplateErrors",
      {},
      {
        root: true,
      }
    );
  },

  methods: {
    getDefaultSizeRangesByFieldType(inputType) {
      if (
        inputType === "SINGLE_LINE_TEXT" ||
        inputType === "PASSWORD" ||
        inputType === "EMAIL" ||
        inputType === "MASKED" ||
        inputType === "HYPER_LINK"
      ) {
        inputType = "SINGLE_LINE_TEXT";
      } else if (inputType === "MULTI_LINE_TEXT" || inputType === "RICH_TEXT") {
        inputType = "SINGLE_LINE_TEXT";
      } else if (
        inputType === "NUMBER" ||
        inputType === "PHONE" ||
        inputType === "FAX" ||
        inputType === "CURRENCY"
      ) {
        inputType = "NUMBER";
      } else if (
        inputType === "DATE" ||
        inputType === "TIME" ||
        inputType === "DATE_TIME"
      ) {
        inputType = "DATE";
      }
      let data = this.fieldActionsObject[inputType];
      return {
        min_height: data.min_height,
        min_width: data.min_width,
        max_height: data.max_height,
        max_width: data.max_width,
      };
    },
    next() {},
    getStyle(item) {
      if (this.interactiveQuestionsMode) {
        if (this.interactiveQuestionsModeType == "vertical") {
          return `position:relative;margin-top:50px; margin-bottom:20px; width:800px;`;
          // return `position:relative;left:${50}px;top:${this.getFieldYValueFromHeight(
          //   item
          // )}px;`;
        } else {
          return `position:relative;margin-top:50px; margin-bottom:20px; width:800px;`;
        }
      } else {
        return `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;`;
      }
    },

    getFieldYValueFromHeight(item) {
      if (item.input_type == "QUESTION") {
        return 150;
      }
      return 80;
    },
    async setTemplateInfo(templateId) {
      this.loading = true;
      this.loadingText = "Fetching Template...";
      try {
        await this.$store.dispatch(
          "companyTemplates/fetchSingleCompanyTemplate",
          templateId
        );

        await this.setTemplateElements(this.getSingleCompanyTemplate);
      } catch (err) {
        console.log("setTemplateInfo",err);
      }

      this.loading = false;
    },

    calculateRelativeLeft(x) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * x
        );
      }
      return x;
    },

    calculateRelativeTop(y) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * y
        );
      }
      return y;
    },
    getHandleByType(type) {
      let defaultHandles = ["tl", "tm", "tr", "mr", "br", "bm", "bl", "ml"];
      switch (type) {
        case "SELECT":
          return ["mr", "ml"];
        case "date":
          return ["mr", "ml"];
        case "CHECKBOX":
          return defaultHandles;
        case "RADIO":
          return defaultHandles;
        case "MULTI_LINE_TEXT":
          return ["mr", "ml"];
        default:
          return defaultHandles;
      }
    },
    calculateRelativeWidth(width) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * width
        );
      }
      return width;
    },

    backToTemplates() {
      this.$router.push({ path: "/company-templates" });
    },
    backToEditTemplate() {
      this.$router.push({
        path: "/company-templates/template/" + this.$route.params.template_id,
      });
    },
    setSizeAndPositionForElements(elements) {
      let questionIndex = 1;
      return elements.map((field) => {
        field = {
          ...field,
          ...this.getDefaultSizeRangesByFieldType(field.input_type),
        };
        field.x = this.calculateRelativeLeft(field.x);
        field.y = this.calculateRelativeTop(field.y);

        let width = this.calculateRelativeWidth(field.width);
        let height = this.calculateRelativeWidth(field.height);

        if (width > field.max_width) {
          field.width = field.max_width;
        } else if (width < field.min_width) {
          field.width = field.min_width;
        } else {
          field.width = width;
        }
        if (height > field.max_height) {
          field.height = field.max_height;
        } else if (height < field.min_height) {
          field.height = field.min_height;
        } else {
          field.height = height;
        }
        if (field.type == "FIELD") {
          field.questionIndex = questionIndex;
          questionIndex = questionIndex + 1;
        }
        return field;
      });
    },
    async setTemplateElements(templateData) {
      this.templateIconPath = templateData.icon;
      this.name = templateData.name;
      this.description = templateData.description;
      this.type = templateData.type;
      this.slug = templateData.slug;
      this.storedFormBuilderWidth = templateData.page_base_width;
      this.interactiveQuestionsMode = templateData.use_interactive_questionare;
      this.interactiveQuestionsModeType =
        templateData.interactive_questionare_type;
      this.templateStyles = templateData.styles;
      if (this.type == "STANDARD") {
        this.showFields = false;
      } else {
        let defaultSection = templateData.sections.find(
          (x) => x.type == "DEFAULT"
        ); // Todo need to change for multiple sections
        // this.fieldsData = defaultSection.fields;

        // we are sorting all fields depends on x,y cordinates to flow in the form
        defaultSection.fields = defaultSection.fields.sort((a, b) => {
          if (a.y - b.y < 10) {
            return a.x - b.x;
          }
          return a.y - b.y;
        });

        this.fieldsData = this.setSizeAndPositionForElements(
          defaultSection.fields
        );
        this.fieldsData = this.fieldsData.sort((a, b) => a.order - b.order);
        defaultSection.fields = defaultSection.fields.sort((a, b) => {
          return a.y - b.y;
        });

        // let lastElement = defaultSection.fields[this.fieldsData.length - 1];

        this.formHeight = 600;

        this.completedFieldsData = [];

        if (this.interactiveQuestionsMode) {
          let indexs = this.findQuestionIndexs(0);
          this.showNextInteractiveQuestion(indexs, "push");
        }
      }
    },

    findQuestionIndexs(startIndex) {
      let indexs = [];
      while (
        startIndex < this.fieldsData.length &&
        this.fieldsData[startIndex] &&
        this.fieldsData[startIndex].type != "FIELD"
      ) {
        indexs.push(startIndex);
        ++startIndex;
      }

      if (this.fieldsData[startIndex]) {
        indexs.push(startIndex);
      }
      return indexs;
    },

    findPrevQuestionIndexs(startIndex) {
      let indexs = [];

      if (this.fieldsData[startIndex].type == "FIELD") {
        indexs.push(startIndex);
        startIndex--;
      }

      while (
        startIndex < this.fieldsData.length &&
        this.fieldsData[startIndex] &&
        this.fieldsData[startIndex].type != "FIELD"
      ) {
        indexs.push(startIndex);
        --startIndex;
      }

      return indexs;
    },

    moveToNextQuestion() {
      if (this.lastInteactiveQuestionIndex + 1 === this.fieldsData.length) {
        this.allFieldsCompleted = true;
      } else {
        let indexs = this.findQuestionIndexs(
          this.lastInteactiveQuestionIndex + 1
        );
        this.showNextInteractiveQuestion(indexs, "push");
      }
    },

    moveToPrevQuestion() {
      this.showNextInteractiveQuestion(
        [this.lastInteactiveQuestionIndex],
        "pull"
      );
    },
    showNextInteractiveQuestion(indexs, type) {
      this.interactiveQuestionsLoading = true;
      setTimeout(() => {
        this.interactiveQuestionsLoading = false;
        if (type == "push") {
          for (let i = 0; i < indexs.length; i++) {
            let index = indexs[i];
            this.completedFieldsData.push(this.fieldsData[index]);
          }
          this.currentQuestionIndexs = indexs;
        } else {
          for (let i = 0; i < indexs.length; i++) {
            let index = indexs[i];
            this.completedFieldsData.splice(index, 1);
          }

          this.currentQuestionIndexs = this.findPrevQuestionIndexs(
            this.completedFieldsData.length - 1
          );
        }
        this.lastInteactiveQuestionIndex = this.completedFieldsData.length - 1;

        setTimeout(() => {
          // VueScrollTo.scrollTo(
          //   "#currentQuestion" + this.lastInteactiveQuestionIndex,
          //   100,
          //   {
          //     container: "#formBuilder",
          //   }
          // );
        });
      }, 3000);
    },
    doneQuestionare() {
      console.log("DONE");
    },
    async getAllFieldList() {
      try {
        // let params = "form=true&content=true"
        // await this.$store.dispatch("standardFields/getFieldLists", params);
        if (fieldsList) {
          //   let dFieldList = this.fieldsList;
          this.fieldActions = [
            ...fieldsList.allFormFields,
            ...fieldsList.allContentFields,
            ...fieldsList.allAdvancedFields,
          ];
        }
      } catch (error) {
        console.log("getAllFieldList",error);
      }
    },
  },

  watch: {
    form: {
      handler(newValue) {
        if (
          this.fieldsData &&
          this.lastInteactiveQuestionIndex < this.fieldsData.length - 1
        ) {
          let currentField =
            this.completedFieldsData[this.lastInteactiveQuestionIndex];

          if (newValue[currentField.key] && newValue[currentField.key].length) {
            // this.moveToNextQuestion();
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.ques-field-top{
  padding: 2em;
}
.complete-msg{
  margin:0 auto;
  width: 70%;
  text-align: center;
  margin-top: 8%;
 font-size: 18px;
 font-weight: 500;
 padding: 2em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: #fff;
}
.submit-btn {
  border-radius: 2px !important;
  padding: 0.45em 0.65em;
  border: 1px solid #285ed3;
  background: #285ed3;
  color: #ffffff;
  &:hover {
    color: #285ed3 !important;
    background: transparent;
    border: 1px solid #285ed3;
  }
}
.edit-btn {
  border-radius: 2px;
  padding: 0.45em 0.65em;
  border: 1px solid #f68c8c;
  background: transparent;
  color: #f68c8c;
  &:hover {
    color: white;
    background: #f68c8c;
    border: 1px solid #f68c8c;
  }
}
.preview-template-view {
  margin-bottom: 3em;
  h1 {
    margin-bottom: 25px !important;
    font-size: 1.125em;
    font-weight: 500;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .preview-form-header {
    margin-top: 1em;
    h1 {
      font-size: 2.1em;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      letter-spacing: -0.5px;
      color: #1f2f3d;
      margin-bottom: 0.2em !important;
    }
    p {
      margin-top: 0;
      padding: 0.15em 0 1.5em;
      line-height: 1.5 !important;
      color: #5e6d82;
    }
  }
  .header-action-block {
    .default-button {
      display: block;
      text-transform: uppercase;
      color: #5e6d82;
      min-width: 100px;
      margin-top: 0.5em;
      &.el-button--danger {
        color: #ffffff;
      }
    }
  }
  .footer-action-block {
    .el-button {
      display: block;
      margin: 2em auto 0;
      padding: 0.75em 2em;
      width: 180px;
      font-size: 1.125em;
    }
    margin-top: 1em;
  }
  .form-builder {
    position: relative;
    width: 100%;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.05);
    .form-fields-holder {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      margin-left: 100px;

      .each-element img {
        max-width: 100%;
      }
    }
  }
}
</style>
